@import '../../../node_modules/@angular/material/theming';
@import 'src/assets/sass/sources/base/variables';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// **Be sure that you only ever include this mixin once!**
@include mat-core();

// Define the theme.
$open-genes-purple: ( // TODO: доделать, сгененировать цвета
  50: #edeefb,
  100: #b7bcf1,
  200: #b39ddb,
  300: #8986d7,
  400: #7673d1,
  500: #7880e4,
  600: #8986d7,
  700: #7673d1,
  800: #b388ff,
  900: #6360cb,
  A100: #4d58dc,
  A200: #413dba,
  A400: #504dc4,
  A700: #413dba,
  contrast: (
    50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
    A100: $dark-primary-text,
    A200: $light-primary-text,
    A400: $light-primary-text,
    A700: $light-primary-text,
  )
);

// TODO: поправить контрастность палитры на примере mat-progress-bar
$open-genes-primary: mat-palette($open-genes-purple, 400, 500, 600);
$open-genes-accent:  mat-palette($mat-deep-orange, 700, 800, 900);
$open-genes-theme:   mat-light-theme($open-genes-primary, $open-genes-accent);

// Include the theme styles for only specified components:
//@include mat-core-theme($open-genes-theme);
//@include mat-button-theme($open-genes-theme);
//@include mat-checkbox-theme($open-genes-theme);

// Include the theme styles for all Angular Material components:
@include angular-material-theme($open-genes-theme);

// CUSTOM DESIGN, GLOBAL OVERRIDES
// TODO: move into assets folder
// TODO: extend from material components and override styles inside a component

// - Expansion-panel
// Custom design
.mat-expansion-panel {
  box-shadow: none !important;
  border: 1px solid $color-borders;

  .mat-expansion-panel-header {
    padding-left: 10px;
  }
}

// - Tab
.mat-tab-label {
  font-size: $font-size-small;
  padding: 0 8px;
}

// - List
// Better styles for multiline text in list items
.mat-list-base {
  &,
  &[dense] {
    .mat-list-item,
    .mat-list-option {
      height: auto !important;

      .mat-list-item-content {
        padding-top: .5em !important;
        padding-bottom: .5em !important;
      }
    }
  }
}

// - Bottom sheet
mat-bottom-sheet-container {
  &.mat-bottom-sheet-container {
    max-height: calc(100vh - #{$header-height});
    overflow: hidden;
  }

  &.mat-bottom-sheet-size-fix {
    max-width: 600px;
  }
}

// - Button
// mat-flat-button directive when it's on <a> element
a.mat-flat-button {
  line-height: 40px;
}

// - Checkbox
// fix checkbox position for multiline labels
.mat-checkbox {
  .mat-checkbox-label {
    white-space: normal;
  }

  .mat-checkbox-inner-container {
    margin-top: 4px;
  }
}
