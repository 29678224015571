@font-face {
  font-family: 'Font Awesome 5 Pro';
  font-style: normal;
  font-weight: 300;
  src: url("../../fonts/fontawesome/fa-light-300.eot");
  src: url("../../fonts/fontawesome/fa-light-300.eot?#iefix") format("embedded-opentype"), url("../../fonts/fontawesome/fa-light-300.woff2") format("woff2"), url("../../fonts/fontawesome/fa-light-300.woff") format("woff"), url("../../fonts/fontawesome/fa-light-300.ttf") format("truetype"), url("../../fonts/fontawesome/fa-light-300.svg#fontawesome") format("svg"); }

.fal {
  font-family: 'Font Awesome 5 Pro';
  font-weight: 300; }
